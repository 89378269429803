/*

  Styles

*/

/* Import files and libraries
===================================*/
@import '_variables';

/* Basic
====================================*/
html {
  scroll-behavior: smooth;
}
a,
button,
input,
select,
textarea {
  outline: none;

  &:focus,
  &:focus:active {
    outline: none;
  }
}

ul {
  padding-left: 30px;
}

li {
  margin: 3px 0;
}

ol {
  padding-left: 30px;
}
.label {
    color: #e60d0d;
    line-height: 2em;
}

del {
    color: #f00;
    
}
#ui-datepicker-div {
    font-size: initial;
}
.white {
    color: #fff;
    
    a {
    color: #fff;
    }
}
.text-lowercase {
    text-transform: lowercase;
}
.text-uppercase {
    text-transform: uppercase;
}
.orange-block-title {
    
      color: @block-title;
      margin: 0 0 30px;
      text-transform: uppercase;
      font-size: 30px;
}

.indents_p { 
    padding: 10px; 
}
.label_bl {
    font-size: 130%;
    padding: 5px 0;
    margin-bottom: 20px;
}
.label_underl_gr {
    border-bottom: 3px solid #a1ce4d;
}
.label_underl_or {
    border-bottom: 3px solid #ffad00;
}
.label_underl_bl {
	border-bottom: 3px solid #5bc0de;
}


/* colorbox
====================================*/
#colorbox {

	#cboxClose:extend(#modalContent .modal-header .popups-close) {
	
	}
 #cboxWrapper {

	#cboxPrevious {
	    display: inline-block !important;
	    top: 50%;
	    background: none;

		&:before {
		    position: absolute;
		    content: '\f104';
		    display: block;
		    text-indent: 0;
		    font-family: 'font-icons';
		    font-size: 100px;
		    line-height: 0;
		    color: #e4e6e5;
		    z-index: 10;
		}
	}

	#cboxNext {
	    display: inline-block !important;
	    top: 50%;
		right: 10px;
		left: unset;
	    background: none;

		&:before {
		    position: absolute;
		    content: '\f105';
		    display: block;
		    text-indent: 0;
		    font-family: 'font-icons';
		    font-size: 100px;
		    line-height: 0;
		    color: #e4e6e5;
		    z-index: 10;
		}
	}
 }
}
/* Overrides
=================================*/
.btn-box {
    display: inline-block;
    border: solid 1px #3cb0fd;
    padding: 10px 15px;
    text-decoration: none;
    cursor: pointer;
    color: #3cb0fd;
    
    &:hover,
    &:active,
    &:focus,
    &:focus:active {
        background: #3cb0fd;
        text-decoration: none;
        color: #fff;
        }
}
.btn-default,
.btn-primary,
.btn-info{
    display: inline-block;
    color: #fff;
    background: #3cb0fd;
    padding: 10px 15px;
    /*text-decoration: none;
    cursor: pointer;*/
    margin: 5px 0;
    
    &:hover,
    &:active,
    &:focus,
    &:focus:active {
        background: #3ca2e6;
        color: #fff;
        text-decoration: none;
    }
    
    a {
        color: #fff;
        text-decoration: none;
    }
}
.btn {
  text-transform: uppercase;
  font-weight: bold;
  border: 0;
  font-size: 16px;
  background: @btn-bg;
  outline: none;
  /*padding: 15px 20px;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px @btn-shadow;*/
  color: @btn-text;

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background: @btn-bg-hover;
    outline: none;
    color: #ffffff;
  }
}

#block-system-main {
  overflow: hidden;
}

.grippie {
  display: none !important;
}

input.form-text {
  height: 50px;
}

textarea.form-textarea {
  font-size: 13px;
  height: 88px;
  padding: 10px 25px;
  box-shadow: none;
  border-radius: 4px;
}

.landing-region,
.prebottom {

  .block {
    padding: 30px 0;

    .block-title {
      color: @block-title;
      margin: 0 0 40px;
      text-transform: uppercase;
      font-size: 30px;
    }
  }
}



/* Navigation
================================*/

/* Menu */
#navbar {
  background: @navbar-bg;
  border: 0;
  height: auto;
  min-height: 60px;
  margin: 0;
  width: 100%;

  @media @s {
    height: @navbar-height;
  }

  .navbar-toggle {
    float: left;
    height: 60px;
    padding: 20px 0;
    margin: 0 0 0 15px;
    .box-sizing();
    border: 0;
    border-radius: 0;
    background: none;

    &:hover {
      background: none;
    }

    .icon-bar {
      background: @navbar-link;
    }
  }

  .container {
    position: relative;

    @media @s {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }

  .navbar-collapse {
    flex-grow: 1;
    
  }

  .menu {
    display: flex;
    flex-flow: column nowrap;

    @media @s {
      position: relative;
      flex-flow: row nowrap;
    }

    li {
      margin: 0;
      position: static;

      &.dropdown {

        @media @s {

			li {
          		width: 50%;
          		float: left;
			}
        }

        & > a:hover:after {
          border-top: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid;
        }

        & > a {
          padding-right: 18px;

          &:after {
            content: '';
            display: block;
            border-top: 4px solid;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            position: absolute;
            right: 5px;
            top: 50%;
            margin-top: -2px;
          }
        }
      }

      &:hover {

        & > a {
          background: none;
        }

        .caret,
        .dropdown-menu {
          display: block;
        }
      }

      a {
        line-height: 44px;
        text-transform: uppercase;
        color: @navbar-link;
        font-weight: bold;
        padding: 0 15px;
        position: relative;
        display: inline-block;

        @media @s {
          line-height: @navbar-height;
        }

        .caret {
          position: absolute;
          display: none;
          bottom: -10px;
          z-index: 2000;
          border-top: 10px solid @navbar-dropdawn-link-active;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent; 
          left: 50%;
          margin-left: -5px;
        }

        &:hover,
        &:focus {
          background: none;
        }

        &.active,
        &.active-trail {
          background: none;
          color: @navbar-link-active;
        }
      }

      .dropdown-menu {
        display: block;
        position: static;
        background: none;
        border: 0;
        padding: 0 0 0 30px;
        border-radius: 0;
        margin: 0;
        width: 100%;
        box-shadow: none;
        .box-sizing();

        @media @s {
          display: none;
          position: absolute;
          padding: 20px 40px 40px;
          background: @navbar-dropdawn-bg;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

          a {
            color: @navbar-dropdawn-link;
            line-height: @navbar-dropdawn-height;
            height: @navbar-dropdawn-height;
            text-transform: none;

            &.active {
              color: @navbar-dropdawn-link-active;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

/* Region */

.view-tags-type-additionally {
    a.btn-default {
        width: 100%;
    }
}
.region-navigation {
  position: relative;

  .block-menu {
    position: static;
  }
}

.navbar-footer {
  position: absolute;
  top: 0;
  right: 15px;
}
.region-phone {
    height: 60px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    a {
        font-weight: 600;
        text-decoration: none;
        }
}



/* Basic page elements
===================================*/
.page-header {
    color: @page-title;
    text-transform: uppercase;
    font-weight: 500;
}
.main-container {

  .region-sidebar-first,
  .region-secondary-sidebar {
    margin-top: 90px;
  }
}

/* Prebottom region 
=======================================*/

/* Contact block */
.contact_address,
.contact_email,
.contact_phone {
    padding-left: 40px;
    margin-bottom: 10px;
    position: relative;
    
    &:before {
        display: block;
        font-family: 'font-icons';
        position: absolute;
        left: 0;
        line-height: 1;
    }
}
.contact_address:before {
    content: "\E809";
    font-size: 35px;
}
.contact_phone:before {
    content: "\f10b";
    font-size: 40px;
}
.contact_email:before {
    content: "\F0E0";
    font-size: 20px;
}

.contacts_block {
    margin: 15px;
}
.contacts_block_address,
.contacts_block_email,
.contacts_block_phone {

    padding-left: 40px;

    &:before {
        display: block;
        font-family: 'font-icons';
        position: absolute;
        left: 0;
        color: #25a6d0;
        line-height: 1;
    }
}

.contacts_block_address:before {
    content: "\E809";
    font-size: 40px;
}
.contacts_block_phone:before {
    content: "\f10b";
    font-size: 40px;
}
.contacts_block_email:before {
    content: "\F0E0";
    font-size: 30px;
}

.container .p-contacts {
    background: none;
    padding: 0 0 50px 0;
}

/* Logo region
====================================*/
#page-header {
  .box-sizing(content-box);
  width: 100%;
  height: 100%;
  border: none;
  /*font: normal 1.5px/normal Arial, Helvetica, sans-serif;
  color: rgba(255,255,255,1);*/
  -o-text-overflow: clip;
  text-overflow: clip;
  background: -webkit-radial-gradient(rgba(135,147,150,0.46) 0, rgba(0,0,0,0) 19%, rgba(0,0,0,0) 100%), -webkit-radial-gradient(rgba(135,147,150,0.46) 0, rgba(0,0,0,0) 19%, rgba(0,0,0,0) 100%), -webkit-linear-gradient(-85deg, rgba(28,71,114,1) 0, rgba(44,178,156,1) 100%);
  background: -moz-radial-gradient(rgba(135,147,150,0.46) 0, rgba(0,0,0,0) 19%, rgba(0,0,0,0) 100%), -moz-radial-gradient(rgba(135,147,150,0.46) 0, rgba(0,0,0,0) 19%, rgba(0,0,0,0) 100%), -moz-linear-gradient(175deg, rgba(28,71,114,1) 0, rgba(44,178,156,1) 100%);
  background: radial-gradient(rgba(135,147,150,0.46) 0, rgba(0,0,0,0) 19%, rgba(0,0,0,0) 100%), radial-gradient(rgba(135,147,150,0.46) 0, rgba(0,0,0,0) 19%, rgba(0,0,0,0) 100%), linear-gradient(175deg, rgba(28,71,114,1) 0, rgba(44,178,156,1) 100%);
  background-position: 0 5px, 5px 0, 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: 10px 10px, 10px 10px, auto auto;
  background-size: 10px 10px, 10px 10px, auto auto;

  p.lead {
    color: @logo-color;
    font-size: 16px;
    text-align: center;
  }
}


/* toTop arrow
============================ */
#block-to-top-arrow {
  padding: 0;
  margin: 0;
  position: fixed;
  bottom: 5px;
  left: 10px;
  z-index: 1000;
}
.to-top-arrow {
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: block;
	background-color: #a0a0a0;

	.fi {
	    font-size: 3.6em;
	    margin-left: 9px;
	    margin-right: 9px;
	    bottom: 0;
	}
}
/* Slick arrows
================================*/
.slick {
  position: relative;
  margin-bottom: 15px;
}
.slick__arrow {
    position: absolute;
    top: 50%;
    margin-top: -60px;
    width: 100%;
    height: 1px;
    overflow: visible;

    .slick-arrow {
      border: 0;
      background: none;
      outline: none;
      text-indent: -999em;
      position: absolute;
      width: 40px;
      height: 100px;
      top: 0;
      
      &:before {
        position: absolute;
        top: 0;
        content: '';
        display: block;
        text-indent: 0;
        font-family: @font-icon;
        font-size: 100px;
        line-height: 1;
        color: #e4e6e5;
        z-index: 10;
      }
    }

    .slick-prev {
      left: 0px;

	  @media @s {
        left: 35px;
      }

      &:before {
        content: '\f104';
        left: 0;
      }
    }

    .slick-next {
      right: 0px;

      @media @s {
        right: 35px;
      }

      
      &:before {
        content: '\f105';
        right: 0;
      }
    }
}

/* node gallery
================================*/
.slick--optionset--node-gallery {


    .slick-prev {
      left: 20px;

	  @media @s {
        left: 35px;
      }

	  @media @l {
        left: 280px;
      }
    }

    .slick-next {
      right: 20px;

      @media @s {
        right: 35px;
      }

      @media @l {
        right: 280px;
      }
    }

}

.slick--optionset--rooms {

	img {
	    margin: 0 auto;
	}

    .slick-next {
      right: 30px;

		@media @s {
		        right: 65px;
		      }
    }

}

/* Colorbox
===================================*/

#colorbox {
  overflow: visible !important;

  #cboxWrapper {
    background: none;
    border-radius: 5px;
    overflow: visible !important
  }

  #cboxLoadedContent {
    margin: 0;
  }

  #cboxContent {
    background: none;
    overflow: visible !important
  }

  #cboxTopLeft,
  #cboxTopRight,
  #cboxTopCenter,
  #cboxMiddleLeft,
  #cboxMiddleRight,
  #cboxCurrent,
  #cboxPrevious,
  #cboxNext {
    display: none !important;
  }

  #cboxClose {
    top: -30px;
    right: -30px;
  }

  #cboxTitle {
    bottom: 0;
  }
}


/* Footer
=====================================*/
.footer {
  background: @footer-bg;
  padding: 20px 0 10px 0;
  color: @footer-text;
}

.policy {
	margin: 30px 0;
}

/* Breadcrumb 
===================*/
.breadcrumb {
    padding: 0;
    a {
        color: @breadcrumbs-link;
    }
}


/* Catalog 
======================================*/
.vni-hotel {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.18);
    background: @catalog-bg;
    margin: 5px auto 20px;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    width: 330px;

    @media @s {
      flex-flow: row nowrap;
      padding-bottom: 0px;
      width: 99%;
      height: 230px;
    }
}
.vni-promo {
    position: absolute;
    width: 100%;
    height: 40px;
    background-color: #a1ce4d;
    z-index: 1;
    line-height: 40px;
    text-align: center;
    color: white;
    top: 0;
}

.vni-photo {
    width: 330px;
    min-width: 330px;
    position: relative;
    overflow: hidden;

    @media @s {
		width: 230px;
    }

    @media @m {
		width: 330px;
    }

    img {

		@media @s {
			max-width: 120%;
			height: 230px;
        }

        @media @m {
			max-width: 100%;
			height: 230px;
			margin-left: 0;
        }
    }
}
.vni-price-from {
    position: absolute;
    bottom: 20px;
    right: 20px;
	background: #27a6d1;
    color: #ffffff;
	font-weight: bold;
	display: inline-block;
	padding: 5px 15px;
}
.vni-right {
    flex-grow: 1;
    margin: 0 15px;
}
.vni-top {
    border-bottom: 1px solid @catalog-border;
}
a.vni-name {
    display: block;
    margin-top: 10px;
    font-size: 20px;
}
.vni-region {
    margin-bottom: 5px;
	margin-right: 80px;
	color: darken(@catalog-border, 30%);

    a {
        color: darken(@catalog-border, 30%);
        font-size: 13px;
    }
}
 a.vni-t-maps-l {
    float: right;
    line-height: 20px;
	width: 75px;
	margin-right: -75px;
	text-decoration: none;
	
	&:before {
        content: '\E809';
        font-family: 'font-icons';
        font-size: 20px;
        color: #d3d5d8;
        padding-right: 5px;
        vertical-align: middle;
    }
}

.vni-mid {
    margin: 5px 0 10px;
    border-bottom: 1px solid #d6d6d6;

	@media @s {
		height: 6em;
		overflow: auto;
	}

    .img-responsive {
        display: inline-block;
        margin: 0 8px 5px 0;
        border: 1px solid #c9cccc;
        width: 35px;
        height: 35px;
    }

	&::-webkit-scrollbar-button { 
		display: none; 
		height: 13px; 
		border-radius: 0px; 
		background-color: #aaaaaa; 
	} 
	&::-webkit-scrollbar-button:hover { 
		background-color: #b9b9b9; 
	} 
	&::-webkit-scrollbar-thumb { 
		background-color: #CCC; 
	} 
	&::-webkit-scrollbar-thumb:hover { 
		background-color: #CCC; 
	} 
	&::-webkit-scrollbar-track { 
		background-color: #efefef; 
	} 
	&::-webkit-scrollbar-track:hover { 
		background-color: #CCC; 
	} 
	&::-webkit-scrollbar{ 
		width: 5px; 
	}
}
.vni-bottom {
    overflow: hidden;
    margin-bottom: 5px;
}   
      
a.vni-b-price-dt {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    text-transform: lowercase;
    width: 90px;
    line-height: 18px;
    padding-left: 30px;
    color: #27a6cf;
    position: relative;
    min-height: 20px;
    
    &:before {
        content: '\F158';
        font-family: 'font-icons';
        font-size: 28px;
        color: #d3d5d8;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -14px;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
    }
}
a.vni-b-comments {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    color: #8a8a8a;
    padding-left: 35px;
    margin-left: 10px;
    position: relative;
    line-height: 36px;
	text-transform: lowercase;
	    
    &:before {
        content: '\E802';
        font-family: 'font-icons';
        font-size: 28px;
        color: #d3d5d8;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -14px;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
    }
}
a.vni-b-book {
    float: right;
    font-weight: bold;
    border: 0;
    background: #ffad00;
    padding: 0 20px;
    letter-spacing: 0.5px;
    color: #ffffff;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    text-decoration: none;
}
a.vni-b-book:hover {
    background: #db9500;
    outline: none;
}

/* Pager
=========================*/
.pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;

  li {
    width: 60px;

    &.pager-first,
    &.pager-last {
      display: none;


	  @media @s {
        display: inherit;
      }
    }

    &.prev {
      width: 100%;

      a {
        font-size: 38px;
      }
    }

    &.next {
      width: 100%;

      a {
        float: right;
        font-size: 38px;
      }
    }

    a {
      width: 50px;
      height: 50px;
      line-height: 50px;
      color: #b9babc;
      background: #f6fafb;
      border-radius: 50%;
      border: 0;
      text-align: center;
      padding: 0;
      margin: 0 5px;
      font-weight: 100;
      font-size: 18px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -o-box-sizing: border-box;
      box-sizing: border-box;

      &:hover {
        color: #27a6d1;
        background: #e9edef;
      }
    }

    &.active {

      span:extend(.pagination li a) {
        color: #ffffff;
        background: #27a6d1;

        &:hover {
          background: #27a6d1;
        }
      }
    }

    &.pager-ellipsis {

      span:extend(.pagination li a) {
        background: none;
        color: #9fa0a3;
      }
    } 
  }
}

/* Call back block 
====================================*/
#block-back-call {
  /*padding: 0;*/

  @-webkit-keyframes pulse {

    to {
      box-shadow: 0 0 0 15px rgba(90, 153, 220, 0), inset 0 1px 0 #abcbe9;
    }
  }

  @-moz-keyframes pulse {

    to {
      box-shadow: 0 0 0 15px rgba(90, 153, 220, 0), inset 0 1px 0 #abcbe9;
    }
  }

  @keyframes pulse {

    to {
      box-shadow: 0 0 0 15px rgba(90, 153, 220, 0), inset 0 1px 0 #abcbe9;
    }
  }
}
.back-call {
    width: 100px;
    position: fixed;
    right: 0;
    text-align: center;
    bottom: 70px;
    z-index: 1;
    height: 100px;
}
.call-button {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -35px;
    margin-top: -35px;
    display: block;
    width: 70px;
    height: 70px;
    font-size: 3.6em;
    font-weight: bold;
    color: #FFF;
    text-shadow: 0 1px 0 #18BF26;
    border: 1px solid #18BF26;
    box-shadow: 0 0 0 0 rgba(56, 204, 34, 0.7), inset 0 1px 0 #AFE9AB;
    border-radius: 100%;
    vertical-align: middle;
    background: #42AF1E;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%,
    color-stop(0%, #47BB2B), color-stop(100%, rgba(72, 207, 70, 0.77)));
    background-image: -webkit-linear-gradient(rgba(126, 217, 110, 0.92), #3AA926);
    background-image: -moz-linear-gradient(#6ea6d9, #468ccf);
    background-image: -o-linear-gradient(#6ea6d9, #468ccf);
    background-image: linear-gradient(#31CE2A, #3DBB2F);
    cursor: pointer;
    -webkit-animation: pulse 1s infinite cubic-bezier(0.6, 0, 0, 1);
     -moz-animation: pulse 1s infinite cubic-bezier(0.6, 0, 0, 1);
    animation: pulse 1s infinite cubic-bezier(0.6, 0, 0, 1);

    &:hover {
      -webkit-animation: none;
      -moz-animation: none;
      animation: none;
    }

	a {
		position: absolute;
    	top: 0;
    	left: 0;
    	bottom: 0;
    	right: 0;
    	bottom: 0;
    	width: 100%;
    	height: 100%;

		.fi {
			color: #fff;
		}
	}
}

/* Modal form 
==================================*/
#modalBackdrop,
.modal-dialog {
  background: #27a6d1 !important;
}

body.modal-open {
  overflow: auto;
  overflow-x: hidden;
}

#modalContent {

  max-width: 90%;
  padding: 0;

  .ctools-modal-content {
		width: 600px;
		max-width: 100%;
    @media @s {
	    width: 850px;
	    max-width: 100%;
		}
  }

  .webform-component--nazvanie {
	display: block;
    color: #a6d15e;
    text-transform: uppercase;
    font-size: 26px;
    font-family: Roboto;
  }

  textarea.form-textarea {
    background: #fbfbfb;
    padding: 10px;
    height: 110px;
    .box-sizing();
    border-radius: 3px;
  }

  .webform-component-select {

    select.form-select {
      height: 50px;
      width: 100%;
    }

    &:after {
      content: '\f107';
      width: 50px;
      height: 50px;
      background: #27a6d1;
      font-family: @font-icon;
      font-size: 26px;
      color: #ffffff;
      line-height: 50px;
      text-align: center;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 0 3px 3px 0;
      pointer-events: none;
    }
  }


  .webform-component-date:extend(#modalContent .webform-component-select) {
    
    .webform-datepicker {
      padding: 0;

      select {
        display: none;
      }

      input {
        width: 100%;
        padding: 10px 50px 10px 10px;
      }
    }

    &:after:extend(#modalContent .webform-component-select:after) {
      content: '\f133';
    }
  }

  fieldset {

    legend {
      background: none;
      border: 0;
      margin: 0 0 0 20px;
      display: block;
      width: auto;

      span {
        color: #007193;
        font-weight: bold;
      }
    }

    .panel-body {
      padding: 10px 5px;
	    @media @s {
		    padding: 10px 15px;
			}
    }
  }

  .modal-header {
    border: none;

    .popups-close {
      position: absolute;
      right: 0;
      top: -50px;
      z-index: 1000;
      opacity: 1;
      width: 45px;
      height: 45px;
      background: url('../images/popup-close.png') no-repeat center center;

      @media @m {
        top: -50px;
        right: -45px;
      }
    }
    
    #modal-title {
      display: none;
    }
  }

  .modal-content {

    box-shadow: none;
    border: 0;

	label {
	      color: #747474;
    }

	.form-actions {
     	padding: 15px;
    	}

	.btn {
	    width: 100%;
		}

  }

  .webform-client-form-2 {
	.webform-component--nazvanie {
        padding: 0 15px 15px;
		}
  }

  .webform-client-form-6 {
    
    #modal-title, {
      display: none;
    }

    .webform-component--kontaktnaya-informaciya--phone label {
      white-space: nowrap;
    }
  }
}
/* Tables
========================= */

.price-table {
    overflow: auto;

    table {
        width: 100%;
        margin: 15px 0;
    
    p {
        margin: 0;
        }
    td,
    th {
        text-align: center;
        padding: 5px;
        border: 1px solid @table-border; 
    }
    
    tr {
    
        &:first-child {
    		background: #bacaca;
    		text-transform: uppercase;
          
          td {
            
            strong {
              color: @table-first-text;
            }
          }
        }
    
        
    
        &:nth-child(2n+1):not(:nth-child(1)) {
          
          td {
            background: @table-odd-even;
          }
        }
    
        th:extend(table tr td) {
          font-weight: 500;
          font-size: 16px;
    
          strong:extend(table tr td strong) {
    
          }
    
          p:extend(table tr td p) {
            
          }
        }
      }   
      
      thead + tbody {
        
        tr {
    
          &:nth-child(2n - 1) {
    
            td {
              background: @table-odd-even;
            }
          }
    
          &:nth-child(2n) {
            
            td {
              background: none;
            }
          }
        }
      }
    }
}


/* Social button
======================== */

#wrapper {
  margin: auto;
  #share {
    position: relative;
    margin:0 auto;
    width: 100%;
    text-align: center;
    .lebel {
      color:white;
      font-size: 16px;
    }
  }
}
.share-container {
  position: relative;
  display:inline-block;
  width: 100px;
  height: 100px;
  line-height:100px;
  text-align:center;
  margin:0 auto;  
  
  .circle {
    fill: none;
    stroke: #ffffff;
    stroke-width:5px;
    stroke-dasharray: 40; 
    transition: all .2s ease-in-out;
    animation: outWaveOut 1s cubic-bezier(0.42, 0.0, 0.58, 1.0) forwards;
  }
  .social {
    color:white;
    font-size:1.8em;    
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;    
    transition: all .5s ease-in-out;
  }
    
  &:hover {
    cursor:pointer;   
  }
}

.vk:hover {
  .circle {
      fill:#ffffff;
  fill-opacity : 1;      
  animation: outWaveIn 1s cubic-bezier(0.42, 0.0, 0.58, 1.0) forwards, colorPinterest 1s linear forwards;
  }
  .social {color:#507299;}
}
.twitter:hover {
  .circle {
    fill:#ffffff;
    fill-opacity : 1;      
    animation: outWaveIn 1s cubic-bezier(0.42, 0.0, 0.58, 1.0) forwards, colorTwitter 1s linear forwards;
  }
  .social {color:#3aaae1;}
}
.facebook:hover {
  .circle {
      fill:#ffffff;
  fill-opacity :1;      
  animation: outWaveIn 1s cubic-bezier(0.42, 0.0, 0.58, 1.0) forwards, colorFacebook 1s linear forwards;
  }
  .social {color:#3b5998;}
}
.instagram:hover {
  .circle {
      fill:#ffffff;
  fill-opacity : 1;      
  animation: outWaveIn 1s cubic-bezier(0.42, 0.0, 0.58, 1.0) forwards, colorGoogle 1s linear forwards;
  }
  .social {color:#d10869;}
}

@keyframes colorPinterest {
  from {stroke: #ffffff;}
  to {stroke:#507299;}
}
@keyframes colorFacebook {
  from {stroke: #ffffff;}
  to {stroke:#3b5998;}
}
@keyframes colorGoogle {
  from {stroke: #ffffff;}
  to {stroke:#d10869;}
}
@keyframes colorTwitter {
  from {stroke: #ffffff;}
  to {stroke:#3aaae1;}
}

@keyframes outWaveIn {
  to {
    stroke-width:10px;
    stroke-dasharray: 400;    
  }
}
@keyframes outWaveOut {
  from {
    stroke-width:10px;
    stroke-dasharray: 400;
  }
  to {
    stroke: #ffffff;
    stroke-width:5px;
    stroke-dasharray: 40; 
  }
}

/* views-exposed-form
======================== */

.main-container {


	.views-exposed-form {
		margin-bottom: 1em;

		.views-exposed-widgets {

			.views-exposed-widget {

				.control-label {
					padding-top: 5px;

					@media @s {
						padding-top: 30px;
					}
				}

				label {
				color: #808080;
				}
				
				.form-submit {
                    width: 100%;
                    margin-top: 0.5em;
                }
			}
		}
	}
}

/* view-term-description+
======================== */
.view-term-description,
.view-new-year,
.view-podmoskovie {

	h2,
	h3,
	.group-label {
		    color: #007196;
	}
	.group-label {
		    font-size: 24px;
	}
}
/* view-copywriter-links+
======================== */
.view-copywriter-links {
	.btn {
		margin: 5px;
		font-weight: normal;
        font-size: inherit;
	}
}

/* view-slider
======================== */
#block-views-slider-block {
	margin: 3px 0;

 @media @s {
	.view-slider {
		.carousel-control {
			.icon-next,
			.icon-prev {
			    border-radius: 25px;
			    width: 50px;
			    height: 50px;
			    background-size: 16px 16px;
			    top: 50%;
			    margin-top: -25px;
			    background-color: #0000004d;
			    display: block;
			    visibility: visible;
	
				&:before {
				    font-size: 50px;
					font-family: "font-icons";
				}
			}
	
			.icon-next {
			    right: 20px;
	
				&:before {
					content: '\f105';
				}
			}
			.icon-prev {
			    left: 20px;
	
				&:before {
					content: '\f104';
				}
			}
		}
	
		.carousel-indicators {
		    bottom: 0;
			right: 10px;
			width: 100%;
		    margin-left: 0;
		    text-align: right;
			left: unset;
			margin-bottom: 0;

			@media @m {
				margin-bottom: 5px;
				right: 30px;
			}
	
			.active {
			    width: 20px;
			    height: 20px;
				margin: 1px;
				background-color: #fff;
			}
	
			li {
			    width: 20px;
	    		height: 20px;
				border-radius: 3px;
	    		background-color: #ffffff63;
				border: none;

				&:hover {
					background-color: #fff;
				}
			}
		}
	}
 }
}

/* region-footer
======================== */

div#block-block-60 {
    /*background-color: #0d7598;
    margin: 30px 0 0;*/
    padding-bottom: 35px;
}

.region-title {
    font-size: 150%;
    margin: 20px 0 10px 0;
}
/* view-filters+
======================== */
    #views-exposed-form-ou-page-10,
    #views-exposed-form-taxonomy-term-tags-page-1 {
        .views-exposed-form {
            .views-exposed-widgets {
                .views-reset-button,
                .views-submit-button {

                    .btn {
                        margin: 0;
                        border-radius: 0;
                        height: 43px;
                        padding: 10px;
                    }
                }
                .views-exposed-widget {
                    padding: 0;

                    .control-label {
                        width: 100%;
                        padding: 0 10px 10px 0;
                        font-size: 100%;
                        @media @s{
                            font-size: 120%;
                        }
                        
                        input[type=checkbox] + span {
                        	display: inline-block;
                            border: solid 1px #3cb0fd;
                            padding: 9px 15px;
                            text-decoration: none;
                            color: #3cb0fd;
                            width: 100%;
                            
                            &:hover,
                            &:active,
                            &:focus,
                            &:focus:active {
                                background: #3cb0fd;
                                text-decoration: none;
                                color: #fff;
                            }
                        }
                        
                        input[type=checkbox]:checked + span {
                        	background: #3cb0fd;
                            text-decoration: none;
                            color: #fff;
                            width: 100%;
                        }
                        
                        input {
                            display: none;
                        }
                    }
                }
            }
        }
    }


/* sale-form+
======================== */
.sale-check-price-result {
    table {
        background: white;
        width: 100%;
        max-width: 100%;
        
        td,
        th {
            padding: 8px;
        }
        
        td {
            border-top: 1px solid #ddd;
        }
        tr.csas-oner-root.csas-oned {
            background-color: #26afd3 !important;
            color: white;
            
            .sale-ques,
            .sale-requ {
                color: #26afd3;
            }
        }
        td.sale-cate-room {
            font-weight: 700;
            color: #337ab7;
        }
    }
}


    .sale-calc-tour {
        background: #3071b1;
        width: auto;
        
        #sale_check_price_result {
            overflow: auto;
        }
        
        .sale-date-range-picker-wr {
            input.form-text {
                padding: 10px 35px 10px 15px;
            }
            .fa-calendar:before {
                content: '\f133';
                font-family: 'font-icons';
                display: block;
                padding: .5rem;
                border: 1px solid #ccc;
                color: #ccc;
            }
        }
        .sale-check-price-result-empty {
            background: #ecb005;
            color: #fff;
            padding: 10px;
            font-size: 17px;
        }
        
        .csas-oner-root {

            .sale-pref,
            .sale-suff {
                color: unset;
            }
            .sale-pvie {
                font-size: 18px;
                color: #da0422;
            }
        }
        
        .csas-oner-child {
            .sale-guar {
                color: #008000;
            }
        }
        
        h2 {
            color: #fff !important;
        }
        .pull-left {
            .control-label {
                color: #fff;
                font-weight: normal;
                
                span.csas-label {
                    font-weight: normal;
                }
            }
            .form-control {
                height: 50px;
            }
            
        }
        
        .btn {
            margin-top: 35px;
        }
        
        .table.sticky-enabled {
            background: #ffffff;
            margin-bottom: 0;
        }
        .form-required {
            color: #f00;
        }
    }
    
.sale-ques,
.sale-requ {
    color: #ffffff;
}
a.sale-order {
    color: white;
    background: red;
    padding: 5px;
    float: right;
}

/* Icons+
====================================*/

@font-face {
  font-family: 'font-icons';
  font-display: swap;
  src: url('../fonts/icons/font-icons.eot');
  src: url('../fonts/icons/font-icons.eot?93957452#iefix') format('embedded-opentype'),
       url('../fonts/icons/font-icons.woff2') format('woff2'),
       url('../fonts/icons/font-icons.woff') format('woff'),
       url('../fonts/icons/font-icons.ttf') format('truetype'),
       url('../fonts/icons/font-icons.svg?93957452#font-icons') format('svg');
  font-style: normal;
  font-weight: normal;
}

.fi {
	display: inline-block;
    font-family: font-icons;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e801'; } /* '' */
.icon-comment:before { content: '\e802'; } /* '' */
.icon-doc:before { content: '\e803'; } /* '' */
.icon-ok:before { content: '\e804'; } /* '' */
.icon-down-dir:before { content: '\e805'; } /* '' */
.icon-up-dir:before { content: '\e807'; } /* '' */
.icon-clock:before { content: '\e808'; } /* '' */
.icon-right-open:before { content: '\f006'; } /* '' */
.icon-left-open:before { content: '\f007'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-angle-double-up:before { content: '\f102'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-mobile:before { content: '\f10b'; } /* '' */
.icon-calendar-empty:before { content: '\f133'; } /* '' */
.icon-rouble:before { content: '\f158'; } /* '' */
.icon-vkontakte:before { content: '\f189'; } /* '' */
.icon-cab:before { content: '\f1b9'; } /* '' */
.icon-lifebuoy:before { content: '\f1cd'; } /* '' */
.icon-percent:before { content: '\f295'; } /* '' */
.icon-instagram:before { content: '\f31e'; } /* '' */
.icon-location:before { content: '\e809'; } /* '' */