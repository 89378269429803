// out: false

/*

  Variables

*/


/* Fonts
==============================*/
@font: 'Arial', sans-serif;
@font-icon: 'font-icons';


/* Breakpoints 
=============================*/
@s: ~'(min-width: 768px)';
@m: ~'(min-width: 992px)';
@l: ~'(min-width: 1200px)';


/* Variable
===========================*/

/* Basic */
@btn-bg: #ffad00;
@btn-bg-hover: #db9500;
@btn-text: #ffffff;
@btn-shadow: rgba(0, 0, 0, 0.2);
@page-title: #a6d15e;
@font-color: #000000;
@block-title: #ffae02;

/* Tables */
@table-border: #d6d8d8;
@table-odd-even: #e3e4e4;
@table-first-text: @font-color;
@table-second-text: #9a9a9c;

/* Colorbox */
@colorbox-overlay: #053848;

/* Navbar */
@navbar-bg: #ffffff;
@navbar-height: 60px;
@navbar-link: #027095;
@navbar-link-active: #ffc059;
@navbar-dropdawn-bg: #28add8;
@navbar-dropdawn-link: #cee3ee;
@navbar-dropdawn-link-active: #ffffff;
@navbar-dropdawn-height: 40px;
@navbar-filter-height: 48px;

/* Nav region */
@callblock-link: #25a6d0;

/* Footer */
@footer-bg: #0685b0;
@footer-text: #ffffff;

/* Sidebar */
@sidebar-block-bg: #ffffff;
@sidebar-block-title-bg: #ffad00;
@sidebar-block-title-text: #ffffff;

/* Breadcrumbs */
@breadcrumbs-link: #646464;

/* Logo region */
@logo-bg1: #266088;
@logo-bg2: #227a84;
@logo-bg3: #32b09a;
@filter-bg: rgba(255, 255, 255, 0.2);
@filter-border: rgba(255, 255, 255, 0.08);
@logo-color: #ffffff;

/* Prebottom */
@tour-text: #ffffff;
@contact-block-icon: #25a6d0;
@contact-block-link: #3d3d3d;

/* Facet filters */
@facet-bg: #f8f9fb;
@facet-title: #007098;
@facet-link: #646464;
@facet-link-arrow: #d6d6d6;
@facet-menu-bg: #ffffff;

/* Pager */
@pager-size: 50px;
@pager-link: #b9babc;
@pager-link-active: #ffffff;
@pager-link-hover: #27a6d1;
@pager-bg: #f6fafb;
@pager-bg-hover: #e9edef;
@pager-active-bg: #27a6d1;

/* Landing region */
@pred-title: #26a5d0;
@pred-img-size: 180px;
@pred-link: #1e7591;
@ways-title: #a0cf4f;
@ways-bg: #ffffff;
@ways-link: #1e7591;
@ways-border: #26a5d2;
@frontpage-block-text: #646464;

/* Nodemenu */
@nodemenu-bg: #ffffff;
@nodemenu-bg-active: #7eb617;
@nodemenu-link: #7eb617;
@nodemenu-link-active: #ffffff;

/* Node */
@node-group-label: #a6d15e;
@node-group-border: #cccccc;
@node-icon-border: #f2f2f2;
@node-icon-bg: #ffffff;
@slide-border: #a0cf4f;
@slide-region: #cccccc;
@slide-price-bg: #27a6d1;
@slide-price-text: #ffffff;

/* Comments */
@comments-title: #a6d15e;
@comments-border: #d6d6d6;
@comments-name: #007297;
@comments-period: #8d8d8d;
@comments-text: #576274;
@comments-add-text: #ffffff;
@comments-add-bg: #27a6d1;

/* catalog */
@catalog-bg: #ffffff;
@catalog-border: #d6d6d6;
@catalog-title: #007196;
@catalog-link: #27a6cf;
@catalog-btn: #ffad00;
@catalog-btn-text: #ffffff;
@catalog-btn-hover: #db9500;
@catalog-sale-bg: #a1ce4d;
@catalog-sale-text: #ffffff;


/* Mixins
================================*/

/* Box sizing */
.box-sizing(@type: border-box) {
  -webkit-box-sizing: @type;
     -moz-box-sizing: @type;
       -o-box-sizing: @type;
          box-sizing: @type;
}

/* Rotate */
.rotate(@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform:    rotate(@deg);
  -ms-transform:     rotate(@deg);
  -o-transform:      rotate(@deg);
  transform:         rotate(@deg);
}











