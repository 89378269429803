/*

  Styles

*/
/* Import files and libraries
===================================*/
/*

  Variables

*/
/* Fonts
==============================*/
/* Breakpoints 
=============================*/
/* Variable
===========================*/
/* Basic */
/* Tables */
/* Colorbox */
/* Navbar */
/* Nav region */
/* Footer */
/* Sidebar */
/* Breadcrumbs */
/* Logo region */
/* Prebottom */
/* Facet filters */
/* Pager */
/* Landing region */
/* Nodemenu */
/* Node */
/* Comments */
/* catalog */
/* Mixins
================================*/
/* Box sizing */
/* Rotate */
/* Basic
====================================*/
html {
  scroll-behavior: smooth;
}
a,
button,
input,
select,
textarea {
  outline: none;
}
a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus,
a:focus:active,
button:focus:active,
input:focus:active,
select:focus:active,
textarea:focus:active {
  outline: none;
}
ul {
  padding-left: 30px;
}
li {
  margin: 3px 0;
}
ol {
  padding-left: 30px;
}
.label {
  color: #e60d0d;
  line-height: 2em;
}
del {
  color: #f00;
}
#ui-datepicker-div {
  font-size: initial;
}
.white {
  color: #fff;
}
.white a {
  color: #fff;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.orange-block-title {
  color: #ffae02;
  margin: 0 0 30px;
  text-transform: uppercase;
  font-size: 30px;
}
.indents_p {
  padding: 10px;
}
.label_bl {
  font-size: 130%;
  padding: 5px 0;
  margin-bottom: 20px;
}
.label_underl_gr {
  border-bottom: 3px solid #a1ce4d;
}
.label_underl_or {
  border-bottom: 3px solid #ffad00;
}
.label_underl_bl {
  border-bottom: 3px solid #5bc0de;
}
/* colorbox
====================================*/
#colorbox #cboxWrapper #cboxPrevious {
  display: inline-block !important;
  top: 50%;
  background: none;
}
#colorbox #cboxWrapper #cboxPrevious:before {
  position: absolute;
  content: '\f104';
  display: block;
  text-indent: 0;
  font-family: 'font-icons';
  font-size: 100px;
  line-height: 0;
  color: #e4e6e5;
  z-index: 10;
}
#colorbox #cboxWrapper #cboxNext {
  display: inline-block !important;
  top: 50%;
  right: 10px;
  left: unset;
  background: none;
}
#colorbox #cboxWrapper #cboxNext:before {
  position: absolute;
  content: '\f105';
  display: block;
  text-indent: 0;
  font-family: 'font-icons';
  font-size: 100px;
  line-height: 0;
  color: #e4e6e5;
  z-index: 10;
}
/* Overrides
=================================*/
.btn-box {
  display: inline-block;
  border: solid 1px #3cb0fd;
  padding: 10px 15px;
  text-decoration: none;
  cursor: pointer;
  color: #3cb0fd;
}
.btn-box:hover,
.btn-box:active,
.btn-box:focus,
.btn-box:focus:active {
  background: #3cb0fd;
  text-decoration: none;
  color: #fff;
}
.btn-default,
.btn-primary,
.btn-info {
  display: inline-block;
  color: #fff;
  background: #3cb0fd;
  padding: 10px 15px;
  /*text-decoration: none;
    cursor: pointer;*/
  margin: 5px 0;
}
.btn-default:hover,
.btn-primary:hover,
.btn-info:hover,
.btn-default:active,
.btn-primary:active,
.btn-info:active,
.btn-default:focus,
.btn-primary:focus,
.btn-info:focus,
.btn-default:focus:active,
.btn-primary:focus:active,
.btn-info:focus:active {
  background: #3ca2e6;
  color: #fff;
  text-decoration: none;
}
.btn-default a,
.btn-primary a,
.btn-info a {
  color: #fff;
  text-decoration: none;
}
.btn {
  text-transform: uppercase;
  font-weight: bold;
  border: 0;
  font-size: 16px;
  background: #ffad00;
  outline: none;
  /*padding: 15px 20px;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px @btn-shadow;*/
  color: #ffffff;
}
.btn:hover,
.btn:active,
.btn:focus,
.btn:focus:active {
  background: #db9500;
  outline: none;
  color: #ffffff;
}
#block-system-main {
  overflow: hidden;
}
.grippie {
  display: none !important;
}
input.form-text {
  height: 50px;
}
textarea.form-textarea {
  font-size: 13px;
  height: 88px;
  padding: 10px 25px;
  box-shadow: none;
  border-radius: 4px;
}
.landing-region .block,
.prebottom .block {
  padding: 30px 0;
}
.landing-region .block .block-title,
.prebottom .block .block-title {
  color: #ffae02;
  margin: 0 0 40px;
  text-transform: uppercase;
  font-size: 30px;
}
/* Navigation
================================*/
/* Menu */
#navbar {
  background: #ffffff;
  border: 0;
  height: auto;
  min-height: 60px;
  margin: 0;
  width: 100%;
}
@media (min-width: 768px) {
  #navbar {
    height: 60px;
  }
}
#navbar .navbar-toggle {
  float: left;
  height: 60px;
  padding: 20px 0;
  margin: 0 0 0 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
  border-radius: 0;
  background: none;
}
#navbar .navbar-toggle:hover {
  background: none;
}
#navbar .navbar-toggle .icon-bar {
  background: #027095;
}
#navbar .container {
  position: relative;
}
@media (min-width: 768px) {
  #navbar .container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
#navbar .navbar-collapse {
  flex-grow: 1;
}
#navbar .menu {
  display: flex;
  flex-flow: column nowrap;
}
@media (min-width: 768px) {
  #navbar .menu {
    position: relative;
    flex-flow: row nowrap;
  }
}
#navbar .menu li {
  margin: 0;
  position: static;
}
@media (min-width: 768px) {
  #navbar .menu li.dropdown li {
    width: 50%;
    float: left;
  }
}
#navbar .menu li.dropdown > a:hover:after {
  border-top: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid;
}
#navbar .menu li.dropdown > a {
  padding-right: 18px;
}
#navbar .menu li.dropdown > a:after {
  content: '';
  display: block;
  border-top: 4px solid;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -2px;
}
#navbar .menu li:hover > a {
  background: none;
}
#navbar .menu li:hover .caret,
#navbar .menu li:hover .dropdown-menu {
  display: block;
}
#navbar .menu li a {
  line-height: 44px;
  text-transform: uppercase;
  color: #027095;
  font-weight: bold;
  padding: 0 15px;
  position: relative;
  display: inline-block;
}
@media (min-width: 768px) {
  #navbar .menu li a {
    line-height: 60px;
  }
}
#navbar .menu li a .caret {
  position: absolute;
  display: none;
  bottom: -10px;
  z-index: 2000;
  border-top: 10px solid #ffffff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: 50%;
  margin-left: -5px;
}
#navbar .menu li a:hover,
#navbar .menu li a:focus {
  background: none;
}
#navbar .menu li a.active,
#navbar .menu li a.active-trail {
  background: none;
  color: #ffc059;
}
#navbar .menu li .dropdown-menu {
  display: block;
  position: static;
  background: none;
  border: 0;
  padding: 0 0 0 30px;
  border-radius: 0;
  margin: 0;
  width: 100%;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
@media (min-width: 768px) {
  #navbar .menu li .dropdown-menu {
    display: none;
    position: absolute;
    padding: 20px 40px 40px;
    background: #28add8;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  #navbar .menu li .dropdown-menu a {
    color: #cee3ee;
    line-height: 40px;
    height: 40px;
    text-transform: none;
  }
  #navbar .menu li .dropdown-menu a.active {
    color: #ffffff;
    font-size: 16px;
  }
}
/* Region */
.view-tags-type-additionally a.btn-default {
  width: 100%;
}
.region-navigation {
  position: relative;
}
.region-navigation .block-menu {
  position: static;
}
.navbar-footer {
  position: absolute;
  top: 0;
  right: 15px;
}
.region-phone {
  height: 60px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.region-phone a {
  font-weight: 600;
  text-decoration: none;
}
/* Basic page elements
===================================*/
.page-header {
  color: #a6d15e;
  text-transform: uppercase;
  font-weight: 500;
}
.main-container .region-sidebar-first,
.main-container .region-secondary-sidebar {
  margin-top: 90px;
}
/* Prebottom region 
=======================================*/
/* Contact block */
.contact_address,
.contact_email,
.contact_phone {
  padding-left: 40px;
  margin-bottom: 10px;
  position: relative;
}
.contact_address:before,
.contact_email:before,
.contact_phone:before {
  display: block;
  font-family: 'font-icons';
  position: absolute;
  left: 0;
  line-height: 1;
}
.contact_address:before {
  content: "\E809";
  font-size: 35px;
}
.contact_phone:before {
  content: "\f10b";
  font-size: 40px;
}
.contact_email:before {
  content: "\F0E0";
  font-size: 20px;
}
.contacts_block {
  margin: 15px;
}
.contacts_block_address,
.contacts_block_email,
.contacts_block_phone {
  padding-left: 40px;
}
.contacts_block_address:before,
.contacts_block_email:before,
.contacts_block_phone:before {
  display: block;
  font-family: 'font-icons';
  position: absolute;
  left: 0;
  color: #25a6d0;
  line-height: 1;
}
.contacts_block_address:before {
  content: "\E809";
  font-size: 40px;
}
.contacts_block_phone:before {
  content: "\f10b";
  font-size: 40px;
}
.contacts_block_email:before {
  content: "\F0E0";
  font-size: 30px;
}
.container .p-contacts {
  background: none;
  padding: 0 0 50px 0;
}
/* Logo region
====================================*/
#page-header {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -o-box-sizing: content-box;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: none;
  /*font: normal 1.5px/normal Arial, Helvetica, sans-serif;
  color: rgba(255,255,255,1);*/
  -o-text-overflow: clip;
  text-overflow: clip;
  background: -webkit-radial-gradient(rgba(135, 147, 150, 0.46) 0, rgba(0, 0, 0, 0) 19%, rgba(0, 0, 0, 0) 100%), -webkit-radial-gradient(rgba(135, 147, 150, 0.46) 0, rgba(0, 0, 0, 0) 19%, rgba(0, 0, 0, 0) 100%), -webkit-linear-gradient(-85deg, #1c4772 0, #2cb29c 100%);
  background: -moz-radial-gradient(rgba(135, 147, 150, 0.46) 0, rgba(0, 0, 0, 0) 19%, rgba(0, 0, 0, 0) 100%), -moz-radial-gradient(rgba(135, 147, 150, 0.46) 0, rgba(0, 0, 0, 0) 19%, rgba(0, 0, 0, 0) 100%), -moz-linear-gradient(175deg, #1c4772 0, #2cb29c 100%);
  background: radial-gradient(rgba(135, 147, 150, 0.46) 0, rgba(0, 0, 0, 0) 19%, rgba(0, 0, 0, 0) 100%), radial-gradient(rgba(135, 147, 150, 0.46) 0, rgba(0, 0, 0, 0) 19%, rgba(0, 0, 0, 0) 100%), linear-gradient(175deg, #1c4772 0, #2cb29c 100%);
  background-position: 0 5px, 5px 0, 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: 10px 10px, 10px 10px, auto auto;
  background-size: 10px 10px, 10px 10px, auto auto;
}
#page-header p.lead {
  color: #ffffff;
  font-size: 16px;
  text-align: center;
}
/* toTop arrow
============================ */
#block-to-top-arrow {
  padding: 0;
  margin: 0;
  position: fixed;
  bottom: 5px;
  left: 10px;
  z-index: 1000;
}
.to-top-arrow {
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: block;
  background-color: #a0a0a0;
}
.to-top-arrow .fi {
  font-size: 3.6em;
  margin-left: 9px;
  margin-right: 9px;
  bottom: 0;
}
/* Slick arrows
================================*/
.slick {
  position: relative;
  margin-bottom: 15px;
}
.slick__arrow {
  position: absolute;
  top: 50%;
  margin-top: -60px;
  width: 100%;
  height: 1px;
  overflow: visible;
}
.slick__arrow .slick-arrow {
  border: 0;
  background: none;
  outline: none;
  text-indent: -999em;
  position: absolute;
  width: 40px;
  height: 100px;
  top: 0;
}
.slick__arrow .slick-arrow:before {
  position: absolute;
  top: 0;
  content: '';
  display: block;
  text-indent: 0;
  font-family: 'font-icons';
  font-size: 100px;
  line-height: 1;
  color: #e4e6e5;
  z-index: 10;
}
.slick__arrow .slick-prev {
  left: 0px;
}
@media (min-width: 768px) {
  .slick__arrow .slick-prev {
    left: 35px;
  }
}
.slick__arrow .slick-prev:before {
  content: '\f104';
  left: 0;
}
.slick__arrow .slick-next {
  right: 0px;
}
@media (min-width: 768px) {
  .slick__arrow .slick-next {
    right: 35px;
  }
}
.slick__arrow .slick-next:before {
  content: '\f105';
  right: 0;
}
/* node gallery
================================*/
.slick--optionset--node-gallery .slick-prev {
  left: 20px;
}
@media (min-width: 768px) {
  .slick--optionset--node-gallery .slick-prev {
    left: 35px;
  }
}
@media (min-width: 1200px) {
  .slick--optionset--node-gallery .slick-prev {
    left: 280px;
  }
}
.slick--optionset--node-gallery .slick-next {
  right: 20px;
}
@media (min-width: 768px) {
  .slick--optionset--node-gallery .slick-next {
    right: 35px;
  }
}
@media (min-width: 1200px) {
  .slick--optionset--node-gallery .slick-next {
    right: 280px;
  }
}
.slick--optionset--rooms img {
  margin: 0 auto;
}
.slick--optionset--rooms .slick-next {
  right: 30px;
}
@media (min-width: 768px) {
  .slick--optionset--rooms .slick-next {
    right: 65px;
  }
}
/* Colorbox
===================================*/
#colorbox {
  overflow: visible !important;
}
#colorbox #cboxWrapper {
  background: none;
  border-radius: 5px;
  overflow: visible !important;
}
#colorbox #cboxLoadedContent {
  margin: 0;
}
#colorbox #cboxContent {
  background: none;
  overflow: visible !important;
}
#colorbox #cboxTopLeft,
#colorbox #cboxTopRight,
#colorbox #cboxTopCenter,
#colorbox #cboxMiddleLeft,
#colorbox #cboxMiddleRight,
#colorbox #cboxCurrent,
#colorbox #cboxPrevious,
#colorbox #cboxNext {
  display: none !important;
}
#colorbox #cboxClose {
  top: -30px;
  right: -30px;
}
#colorbox #cboxTitle {
  bottom: 0;
}
/* Footer
=====================================*/
.footer {
  background: #0685b0;
  padding: 20px 0 10px 0;
  color: #ffffff;
}
.policy {
  margin: 30px 0;
}
/* Breadcrumb 
===================*/
.breadcrumb {
  padding: 0;
}
.breadcrumb a {
  color: #646464;
}
/* Catalog 
======================================*/
.vni-hotel {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.18);
  background: #ffffff;
  margin: 5px auto 20px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  width: 330px;
}
@media (min-width: 768px) {
  .vni-hotel {
    flex-flow: row nowrap;
    padding-bottom: 0px;
    width: 99%;
    height: 230px;
  }
}
.vni-promo {
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: #a1ce4d;
  z-index: 1;
  line-height: 40px;
  text-align: center;
  color: white;
  top: 0;
}
.vni-photo {
  width: 330px;
  min-width: 330px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .vni-photo {
    width: 230px;
  }
}
@media (min-width: 992px) {
  .vni-photo {
    width: 330px;
  }
}
@media (min-width: 768px) {
  .vni-photo img {
    max-width: 120%;
    height: 230px;
  }
}
@media (min-width: 992px) {
  .vni-photo img {
    max-width: 100%;
    height: 230px;
    margin-left: 0;
  }
}
.vni-price-from {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: #27a6d1;
  color: #ffffff;
  font-weight: bold;
  display: inline-block;
  padding: 5px 15px;
}
.vni-right {
  flex-grow: 1;
  margin: 0 15px;
}
.vni-top {
  border-bottom: 1px solid #d6d6d6;
}
a.vni-name {
  display: block;
  margin-top: 10px;
  font-size: 20px;
}
.vni-region {
  margin-bottom: 5px;
  margin-right: 80px;
  color: #8a8a8a;
}
.vni-region a {
  color: #8a8a8a;
  font-size: 13px;
}
a.vni-t-maps-l {
  float: right;
  line-height: 20px;
  width: 75px;
  margin-right: -75px;
  text-decoration: none;
}
a.vni-t-maps-l:before {
  content: '\E809';
  font-family: 'font-icons';
  font-size: 20px;
  color: #d3d5d8;
  padding-right: 5px;
  vertical-align: middle;
}
.vni-mid {
  margin: 5px 0 10px;
  border-bottom: 1px solid #d6d6d6;
}
@media (min-width: 768px) {
  .vni-mid {
    height: 6em;
    overflow: auto;
  }
}
.vni-mid .img-responsive {
  display: inline-block;
  margin: 0 8px 5px 0;
  border: 1px solid #c9cccc;
  width: 35px;
  height: 35px;
}
.vni-mid::-webkit-scrollbar-button {
  display: none;
  height: 13px;
  border-radius: 0px;
  background-color: #aaaaaa;
}
.vni-mid::-webkit-scrollbar-button:hover {
  background-color: #b9b9b9;
}
.vni-mid::-webkit-scrollbar-thumb {
  background-color: #CCC;
}
.vni-mid::-webkit-scrollbar-thumb:hover {
  background-color: #CCC;
}
.vni-mid::-webkit-scrollbar-track {
  background-color: #efefef;
}
.vni-mid::-webkit-scrollbar-track:hover {
  background-color: #CCC;
}
.vni-mid::-webkit-scrollbar {
  width: 5px;
}
.vni-bottom {
  overflow: hidden;
  margin-bottom: 5px;
}
a.vni-b-price-dt {
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  text-transform: lowercase;
  width: 90px;
  line-height: 18px;
  padding-left: 30px;
  color: #27a6cf;
  position: relative;
  min-height: 20px;
}
a.vni-b-price-dt:before {
  content: '\F158';
  font-family: 'font-icons';
  font-size: 28px;
  color: #d3d5d8;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -14px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
}
a.vni-b-comments {
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  color: #8a8a8a;
  padding-left: 35px;
  margin-left: 10px;
  position: relative;
  line-height: 36px;
  text-transform: lowercase;
}
a.vni-b-comments:before {
  content: '\E802';
  font-family: 'font-icons';
  font-size: 28px;
  color: #d3d5d8;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -14px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
}
a.vni-b-book {
  float: right;
  font-weight: bold;
  border: 0;
  background: #ffad00;
  padding: 0 20px;
  letter-spacing: 0.5px;
  color: #ffffff;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  text-decoration: none;
}
a.vni-b-book:hover {
  background: #db9500;
  outline: none;
}
/* Pager
=========================*/
.pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}
.pagination li {
  width: 60px;
}
.pagination li.pager-first,
.pagination li.pager-last {
  display: none;
}
@media (min-width: 768px) {
  .pagination li.pager-first,
  .pagination li.pager-last {
    display: inherit;
  }
}
.pagination li.prev {
  width: 100%;
}
.pagination li.prev a {
  font-size: 38px;
}
.pagination li.next {
  width: 100%;
}
.pagination li.next a {
  float: right;
  font-size: 38px;
}
.pagination li a,
.pagination li.active span,
.pagination li.pager-ellipsis span {
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #b9babc;
  background: #f6fafb;
  border-radius: 50%;
  border: 0;
  text-align: center;
  padding: 0;
  margin: 0 5px;
  font-weight: 100;
  font-size: 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.pagination li a:hover {
  color: #27a6d1;
  background: #e9edef;
}
.pagination li.active span {
  color: #ffffff;
  background: #27a6d1;
}
.pagination li.active span:hover {
  background: #27a6d1;
}
.pagination li.pager-ellipsis span {
  background: none;
  color: #9fa0a3;
}
/* Call back block 
====================================*/
#block-back-call {
  /*padding: 0;*/
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(90, 153, 220, 0), inset 0 1px 0 #abcbe9;
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(90, 153, 220, 0), inset 0 1px 0 #abcbe9;
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(90, 153, 220, 0), inset 0 1px 0 #abcbe9;
  }
}
.back-call {
  width: 100px;
  position: fixed;
  right: 0;
  text-align: center;
  bottom: 70px;
  z-index: 1;
  height: 100px;
}
.call-button {
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  margin-top: -35px;
  display: block;
  width: 70px;
  height: 70px;
  font-size: 3.6em;
  font-weight: bold;
  color: #FFF;
  text-shadow: 0 1px 0 #18BF26;
  border: 1px solid #18BF26;
  box-shadow: 0 0 0 0 rgba(56, 204, 34, 0.7), inset 0 1px 0 #AFE9AB;
  border-radius: 100%;
  vertical-align: middle;
  background: #42AF1E;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #47BB2B), color-stop(100%, rgba(72, 207, 70, 0.77)));
  background-image: -webkit-linear-gradient(rgba(126, 217, 110, 0.92), #3AA926);
  background-image: -moz-linear-gradient(#6ea6d9, #468ccf);
  background-image: -o-linear-gradient(#6ea6d9, #468ccf);
  background-image: linear-gradient(#31CE2A, #3DBB2F);
  cursor: pointer;
  -webkit-animation: pulse 1s infinite cubic-bezier(0.6, 0, 0, 1);
  -moz-animation: pulse 1s infinite cubic-bezier(0.6, 0, 0, 1);
  animation: pulse 1s infinite cubic-bezier(0.6, 0, 0, 1);
}
.call-button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}
.call-button a {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.call-button a .fi {
  color: #fff;
}
/* Modal form 
==================================*/
#modalBackdrop,
.modal-dialog {
  background: #27a6d1 !important;
}
body.modal-open {
  overflow: auto;
  overflow-x: hidden;
}
#modalContent {
  max-width: 90%;
  padding: 0;
}
#modalContent .ctools-modal-content {
  width: 600px;
  max-width: 100%;
}
@media (min-width: 768px) {
  #modalContent .ctools-modal-content {
    width: 850px;
    max-width: 100%;
  }
}
#modalContent .webform-component--nazvanie {
  display: block;
  color: #a6d15e;
  text-transform: uppercase;
  font-size: 26px;
  font-family: Roboto;
}
#modalContent textarea.form-textarea {
  background: #fbfbfb;
  padding: 10px;
  height: 110px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px;
}
#modalContent .webform-component-select select.form-select {
  height: 50px;
  width: 100%;
}
#modalContent .webform-component-select:after,
#modalContent .webform-component-date:after {
  content: '\f107';
  width: 50px;
  height: 50px;
  background: #27a6d1;
  font-family: 'font-icons';
  font-size: 26px;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0 3px 3px 0;
  pointer-events: none;
}
#modalContent .webform-component-date .webform-datepicker {
  padding: 0;
}
#modalContent .webform-component-date .webform-datepicker select {
  display: none;
}
#modalContent .webform-component-date .webform-datepicker input {
  width: 100%;
  padding: 10px 50px 10px 10px;
}
#modalContent .webform-component-date:after {
  content: '\f133';
}
#modalContent fieldset legend {
  background: none;
  border: 0;
  margin: 0 0 0 20px;
  display: block;
  width: auto;
}
#modalContent fieldset legend span {
  color: #007193;
  font-weight: bold;
}
#modalContent fieldset .panel-body {
  padding: 10px 5px;
}
@media (min-width: 768px) {
  #modalContent fieldset .panel-body {
    padding: 10px 15px;
  }
}
#modalContent .modal-header {
  border: none;
}
#modalContent .modal-header .popups-close,
#colorbox #cboxClose {
  position: absolute;
  right: 0;
  top: -50px;
  z-index: 1000;
  opacity: 1;
  width: 45px;
  height: 45px;
  background: url('../images/popup-close.png') no-repeat center center;
}
@media (min-width: 992px) {
  #modalContent .modal-header .popups-close,
  #colorbox #cboxClose {
    top: -50px;
    right: -45px;
  }
}
#modalContent .modal-header #modal-title {
  display: none;
}
#modalContent .modal-content {
  box-shadow: none;
  border: 0;
}
#modalContent .modal-content label {
  color: #747474;
}
#modalContent .modal-content .form-actions {
  padding: 15px;
}
#modalContent .modal-content .btn {
  width: 100%;
}
#modalContent .webform-client-form-2 .webform-component--nazvanie {
  padding: 0 15px 15px;
}
#modalContent .webform-client-form-6 #modal-title {
  display: none;
}
#modalContent .webform-client-form-6 .webform-component--kontaktnaya-informaciya--phone label {
  white-space: nowrap;
}
/* Tables
========================= */
.price-table {
  overflow: auto;
}
.price-table table {
  width: 100%;
  margin: 15px 0;
}
.price-table table p {
  margin: 0;
}
.price-table table td,
.price-table table th {
  text-align: center;
  padding: 5px;
  border: 1px solid #d6d8d8;
}
.price-table table tr:first-child {
  background: #bacaca;
  text-transform: uppercase;
}
.price-table table tr:first-child td strong {
  color: #000000;
}
.price-table table tr:nth-child(2n+1):not(:nth-child(1)) td {
  background: #e3e4e4;
}
.price-table table tr th {
  font-weight: 500;
  font-size: 16px;
}
.price-table table thead + tbody tr:nth-child(2n - 1) td {
  background: #e3e4e4;
}
.price-table table thead + tbody tr:nth-child(2n) td {
  background: none;
}
/* Social button
======================== */
#wrapper {
  margin: auto;
}
#wrapper #share {
  position: relative;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
#wrapper #share .lebel {
  color: white;
  font-size: 16px;
}
.share-container {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  margin: 0 auto;
}
.share-container .circle {
  fill: none;
  stroke: #ffffff;
  stroke-width: 5px;
  stroke-dasharray: 40;
  transition: all 0.2s ease-in-out;
  animation: outWaveOut 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
}
.share-container .social {
  color: white;
  font-size: 1.8em;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}
.share-container:hover {
  cursor: pointer;
}
.vk:hover .circle {
  fill: #ffffff;
  fill-opacity: 1;
  animation: outWaveIn 1s cubic-bezier(0.42, 0, 0.58, 1) forwards, colorPinterest 1s linear forwards;
}
.vk:hover .social {
  color: #507299;
}
.twitter:hover .circle {
  fill: #ffffff;
  fill-opacity: 1;
  animation: outWaveIn 1s cubic-bezier(0.42, 0, 0.58, 1) forwards, colorTwitter 1s linear forwards;
}
.twitter:hover .social {
  color: #3aaae1;
}
.facebook:hover .circle {
  fill: #ffffff;
  fill-opacity: 1;
  animation: outWaveIn 1s cubic-bezier(0.42, 0, 0.58, 1) forwards, colorFacebook 1s linear forwards;
}
.facebook:hover .social {
  color: #3b5998;
}
.instagram:hover .circle {
  fill: #ffffff;
  fill-opacity: 1;
  animation: outWaveIn 1s cubic-bezier(0.42, 0, 0.58, 1) forwards, colorGoogle 1s linear forwards;
}
.instagram:hover .social {
  color: #d10869;
}
@keyframes colorPinterest {
  from {
    stroke: #ffffff;
  }
  to {
    stroke: #507299;
  }
}
@keyframes colorFacebook {
  from {
    stroke: #ffffff;
  }
  to {
    stroke: #3b5998;
  }
}
@keyframes colorGoogle {
  from {
    stroke: #ffffff;
  }
  to {
    stroke: #d10869;
  }
}
@keyframes colorTwitter {
  from {
    stroke: #ffffff;
  }
  to {
    stroke: #3aaae1;
  }
}
@keyframes outWaveIn {
  to {
    stroke-width: 10px;
    stroke-dasharray: 400;
  }
}
@keyframes outWaveOut {
  from {
    stroke-width: 10px;
    stroke-dasharray: 400;
  }
  to {
    stroke: #ffffff;
    stroke-width: 5px;
    stroke-dasharray: 40;
  }
}
/* views-exposed-form
======================== */
.main-container .views-exposed-form {
  margin-bottom: 1em;
}
.main-container .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label {
  padding-top: 5px;
}
@media (min-width: 768px) {
  .main-container .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label {
    padding-top: 30px;
  }
}
.main-container .views-exposed-form .views-exposed-widgets .views-exposed-widget label {
  color: #808080;
}
.main-container .views-exposed-form .views-exposed-widgets .views-exposed-widget .form-submit {
  width: 100%;
  margin-top: 0.5em;
}
/* view-term-description+
======================== */
.view-term-description h2,
.view-new-year h2,
.view-podmoskovie h2,
.view-term-description h3,
.view-new-year h3,
.view-podmoskovie h3,
.view-term-description .group-label,
.view-new-year .group-label,
.view-podmoskovie .group-label {
  color: #007196;
}
.view-term-description .group-label,
.view-new-year .group-label,
.view-podmoskovie .group-label {
  font-size: 24px;
}
/* view-copywriter-links+
======================== */
.view-copywriter-links .btn {
  margin: 5px;
  font-weight: normal;
  font-size: inherit;
}
/* view-slider
======================== */
#block-views-slider-block {
  margin: 3px 0;
}
@media (min-width: 768px) {
  #block-views-slider-block .view-slider .carousel-control .icon-next,
  #block-views-slider-block .view-slider .carousel-control .icon-prev {
    border-radius: 25px;
    width: 50px;
    height: 50px;
    background-size: 16px 16px;
    top: 50%;
    margin-top: -25px;
    background-color: #0000004d;
    display: block;
    visibility: visible;
  }
  #block-views-slider-block .view-slider .carousel-control .icon-next:before,
  #block-views-slider-block .view-slider .carousel-control .icon-prev:before {
    font-size: 50px;
    font-family: "font-icons";
  }
  #block-views-slider-block .view-slider .carousel-control .icon-next {
    right: 20px;
  }
  #block-views-slider-block .view-slider .carousel-control .icon-next:before {
    content: '\f105';
  }
  #block-views-slider-block .view-slider .carousel-control .icon-prev {
    left: 20px;
  }
  #block-views-slider-block .view-slider .carousel-control .icon-prev:before {
    content: '\f104';
  }
  #block-views-slider-block .view-slider .carousel-indicators {
    bottom: 0;
    right: 10px;
    width: 100%;
    margin-left: 0;
    text-align: right;
    left: unset;
    margin-bottom: 0;
  }
  #block-views-slider-block .view-slider .carousel-indicators .active {
    width: 20px;
    height: 20px;
    margin: 1px;
    background-color: #fff;
  }
  #block-views-slider-block .view-slider .carousel-indicators li {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-color: #ffffff63;
    border: none;
  }
  #block-views-slider-block .view-slider .carousel-indicators li:hover {
    background-color: #fff;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  #block-views-slider-block .view-slider .carousel-indicators {
    margin-bottom: 5px;
    right: 30px;
  }
}
/* region-footer
======================== */
div#block-block-60 {
  /*background-color: #0d7598;
    margin: 30px 0 0;*/
  padding-bottom: 35px;
}
.region-title {
  font-size: 150%;
  margin: 20px 0 10px 0;
}
/* view-filters+
======================== */
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-reset-button .btn,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-reset-button .btn,
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-submit-button .btn,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-submit-button .btn {
  margin: 0;
  border-radius: 0;
  height: 43px;
  padding: 10px;
}
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-exposed-widget,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-exposed-widget {
  padding: 0;
}
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label {
  width: 100%;
  padding: 0 10px 10px 0;
  font-size: 100%;
}
@media (min-width: 768px) {
  #views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label,
  #views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label {
    font-size: 120%;
  }
}
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox] + span,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox] + span {
  display: inline-block;
  border: solid 1px #3cb0fd;
  padding: 9px 15px;
  text-decoration: none;
  color: #3cb0fd;
  width: 100%;
}
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox] + span:hover,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox] + span:hover,
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox] + span:active,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox] + span:active,
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox] + span:focus,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox] + span:focus,
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox] + span:focus:active,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox] + span:focus:active {
  background: #3cb0fd;
  text-decoration: none;
  color: #fff;
}
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox]:checked + span,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input[type=checkbox]:checked + span {
  background: #3cb0fd;
  text-decoration: none;
  color: #fff;
  width: 100%;
}
#views-exposed-form-ou-page-10 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input,
#views-exposed-form-taxonomy-term-tags-page-1 .views-exposed-form .views-exposed-widgets .views-exposed-widget .control-label input {
  display: none;
}
/* sale-form+
======================== */
.sale-check-price-result table {
  background: white;
  width: 100%;
  max-width: 100%;
}
.sale-check-price-result table td,
.sale-check-price-result table th {
  padding: 8px;
}
.sale-check-price-result table td {
  border-top: 1px solid #ddd;
}
.sale-check-price-result table tr.csas-oner-root.csas-oned {
  background-color: #26afd3 !important;
  color: white;
}
.sale-check-price-result table tr.csas-oner-root.csas-oned .sale-ques,
.sale-check-price-result table tr.csas-oner-root.csas-oned .sale-requ {
  color: #26afd3;
}
.sale-check-price-result table td.sale-cate-room {
  font-weight: 700;
  color: #337ab7;
}
.sale-calc-tour {
  background: #3071b1;
  width: auto;
}
.sale-calc-tour #sale_check_price_result {
  overflow: auto;
}
.sale-calc-tour .sale-date-range-picker-wr input.form-text {
  padding: 10px 35px 10px 15px;
}
.sale-calc-tour .sale-date-range-picker-wr .fa-calendar:before {
  content: '\f133';
  font-family: 'font-icons';
  display: block;
  padding: .5rem;
  border: 1px solid #ccc;
  color: #ccc;
}
.sale-calc-tour .sale-check-price-result-empty {
  background: #ecb005;
  color: #fff;
  padding: 10px;
  font-size: 17px;
}
.sale-calc-tour .csas-oner-root .sale-pref,
.sale-calc-tour .csas-oner-root .sale-suff {
  color: unset;
}
.sale-calc-tour .csas-oner-root .sale-pvie {
  font-size: 18px;
  color: #da0422;
}
.sale-calc-tour .csas-oner-child .sale-guar {
  color: #008000;
}
.sale-calc-tour h2 {
  color: #fff !important;
}
.sale-calc-tour .pull-left .control-label {
  color: #fff;
  font-weight: normal;
}
.sale-calc-tour .pull-left .control-label span.csas-label {
  font-weight: normal;
}
.sale-calc-tour .pull-left .form-control {
  height: 50px;
}
.sale-calc-tour .btn {
  margin-top: 35px;
}
.sale-calc-tour .table.sticky-enabled {
  background: #ffffff;
  margin-bottom: 0;
}
.sale-calc-tour .form-required {
  color: #f00;
}
.sale-ques,
.sale-requ {
  color: #ffffff;
}
a.sale-order {
  color: white;
  background: red;
  padding: 5px;
  float: right;
}
/* Icons+
====================================*/
@font-face {
  font-family: 'font-icons';
  font-display: swap;
  src: url('../fonts/icons/font-icons.eot');
  src: url('../fonts/icons/font-icons.eot?93957452#iefix') format('embedded-opentype'), url('../fonts/icons/font-icons.woff2') format('woff2'), url('../fonts/icons/font-icons.woff') format('woff'), url('../fonts/icons/font-icons.ttf') format('truetype'), url('../fonts/icons/font-icons.svg?93957452#font-icons') format('svg');
  font-style: normal;
  font-weight: normal;
}
.fi {
  display: inline-block;
  font-family: font-icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-phone:before {
  content: '\e800';
}
/* '' */
.icon-search:before {
  content: '\e801';
}
/* '' */
.icon-comment:before {
  content: '\e802';
}
/* '' */
.icon-doc:before {
  content: '\e803';
}
/* '' */
.icon-ok:before {
  content: '\e804';
}
/* '' */
.icon-down-dir:before {
  content: '\e805';
}
/* '' */
.icon-up-dir:before {
  content: '\e807';
}
/* '' */
.icon-clock:before {
  content: '\e808';
}
/* '' */
.icon-right-open:before {
  content: '\f006';
}
/* '' */
.icon-left-open:before {
  content: '\f007';
}
/* '' */
.icon-facebook:before {
  content: '\f09a';
}
/* '' */
.icon-menu:before {
  content: '\f0c9';
}
/* '' */
.icon-mail-alt:before {
  content: '\f0e0';
}
/* '' */
.icon-angle-double-up:before {
  content: '\f102';
}
/* '' */
.icon-angle-left:before {
  content: '\f104';
}
/* '' */
.icon-angle-right:before {
  content: '\f105';
}
/* '' */
.icon-angle-up:before {
  content: '\f106';
}
/* '' */
.icon-angle-down:before {
  content: '\f107';
}
/* '' */
.icon-mobile:before {
  content: '\f10b';
}
/* '' */
.icon-calendar-empty:before {
  content: '\f133';
}
/* '' */
.icon-rouble:before {
  content: '\f158';
}
/* '' */
.icon-vkontakte:before {
  content: '\f189';
}
/* '' */
.icon-cab:before {
  content: '\f1b9';
}
/* '' */
.icon-lifebuoy:before {
  content: '\f1cd';
}
/* '' */
.icon-percent:before {
  content: '\f295';
}
/* '' */
.icon-instagram:before {
  content: '\f31e';
}
/* '' */
.icon-location:before {
  content: '\e809';
}
/* '' */
